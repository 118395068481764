@import '../../sass/variables.scss';

.landing {
  text-align: center;
  background-color: $astraDarkBlue;
  color: $white;
  height: 100%;
  padding: 36px;

  .text-position {
    text-align: right;
  }
}

img {
  width: 100%;
  max-width: 500px;
  padding: 5%;
}

@media only screen and (max-width: 425px) {
  .float-right {
    width: 100% !important;
  }

  .landing {
    padding: 10px;

    .col-8 {
      flex: 0 0 90% !important;
      max-width: 100% !important;
    }

    .text-position {
      text-align: left;
    }
  }
}
