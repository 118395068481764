@import '../sass//variables.scss';

.btn-astra {
  background-color: $astraBlue;
  color: $astraDarkBlue;

  &:hover {
    background-color: $astraDarkBlue;
    color: $astraBlue;
    border: 1px solid $astraBlue;
  }
}
